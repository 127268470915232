import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Login from './Page/Login';
import Home from './Page/Home';
import ViewReceipt from './Page/ViewReceipt';
import ViewReceiptV2 from './Page/ViewReceiptV2';
import ViewResult from './Page/ViewResult';
import Setting from './Page/Setting';
import Report from './Page/Report';
import RedNumber from './Page/RedNumber';
import VIPuser from './Page/VIPuser';
import SubAcc from './Page/SubAcc';
import AddGame from './Page/AddGame';
import GenerateExcel from './Page/GenerateExcel';
import GameResult from './Page/GameResult';
import TextfileConvert from './Page/TextfileConvert';
import UploadCSV from './Page/UploadCSV';
import HR from './Page/HR';
import betting from './Page/betting';
import BetBoxVersion from './Page/BetBoxVersion';
import BoxVersion from './Page/BoxVersion';
import LongTermReceipt from './Page/LongTermReceipt';
import Dading from './Page/Dading';
import Receipt from './Page/Receipt';
import OpenWindowReceipt from './Page/OpenWindowReceipt';
import FindNumber from './Page/FindNumber';
import BettingVersion2 from './Page/BettingVersion2';
import GrandTotalNumberResult from './Page/GrandTotalNumberResult';
import TodaySalesReport from './Page/TodaySalesReport';
import GrandTotalNumberResultFight from './Page/GrandTotalNumberResultFight';
import DownlineReport from './Page/DownlineReport';
import UserReport from './Page/UserReport';
import BettingSgd from './Page/BettingSgd';
import BettingRed from './Page/bettingRed';
import bettingThai from './Page/bettingThai';
import DadingConvert from './Page/DadingConvert';
import DadingConvertCSV from './Page/DadingConvertCSV';
import IndividualDownlineReport from './Page/IndividualDownlineReport';
import ShowAllSalesReportFight from './Page/ShowAllSalesReportFight';
import ShowAllSalesReport from './Page/ShowAllSalesReport';
import AccountReport from './Page/AccountReport';
import SimpleReport from './Page/SimpleReport';
import Hit_Sales from './Page/Hit_Sales';
import ActionUserRecord from './Page/ActionUserRecord';
import GameSetting from './Page/GameSetting';
import SendSMSGroup from './Page/SendSMSGroup';
import DetailPersonaSales from './Page/DetailPersonaSales';
import FastRegister from './Page/FastRegister';
import CheckNumber from './Page/CheckNumber';
import SixDJackPotList from './Page/SixDJackPotList';
import AccountSalesList from './Page/AccountSalesList';
import UpdateLatestNews from './Page/UpdateLatestNews';
import CreateNews from './Page/CreateNews';
import DadingShowAll from './Page/DadingShowAll';
import DisplayEditPage from './Page/DisplayEditPage';
import SuperAdminManagePage from './Page/SuperAdminManagePage';
import SecondReport from './Page/SecondReport';
import FirstReport from './Page/FirstReport';
import SMSReport from './Page/SMSReport';
import SMSReportDetail from './Page/SMSReportDetail';
import ZhonglaiCalculate from './Page/ZhonglaiCalculate';
import MultiNumberSearch from './Page/MultiNumberSearch';
import FilterDading from './Page/FilterDading';
import TEMP101 from './Page/101';

// import FunctionalComponent from './Page/FunctionalComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';

class AppFunction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return(
      <Router style={{ position: 'relative' }}>
        {/* <Header/> */}
        <div className="App">
          {
            this.props.Role === 'superAdmin' && <Switch>
              {(this.props.BooleanBack === true) && <Route path="*" component={Login} />}
              <Route exact path="/" component={Login} />
              <Route exact path="/Login" component={Login} />
              <Route exact path="/betting" component={betting} />
              <Route exact path="/BettingVersion2" component={BettingVersion2} />
              <Route exact path="/TextfileConvert" component={TextfileConvert} />
              <Route exact path="/UploadCSV" component={UploadCSV} />
              <Route exact path="/Home" component={Home} />
              <Route exact path="/BetBoxVersion" component={BetBoxVersion} />
              <Route exact path="/BoxVersion" component={BoxVersion} />
              <Route exact path="/ViewReceipt" component={ViewReceipt} />
              <Route exact path="/ViewReceiptV2" component={ViewReceiptV2} />
              <Route exact path="/LongTermReceipt" component={LongTermReceipt} />
              <Route exact path="/Receipt" component={Receipt} />
              <Route exact path="/ViewResult" component={ViewResult} />
              <Route exact path="/Report" component={Report} />
              <Route exact path="/RedNumber" component={RedNumber} />
              <Route exact path="/Setting" component={Setting} />
              <Route exact path="/HR" component={HR} />
              <Route exact path="/BettingRed" component={BettingRed} />
              <Route exact path="/BettingSgd" component={BettingSgd} />
              <Route exact path="/bettingThai" component={bettingThai} />
              <Route exact path="/FindNumber" component={FindNumber} />
              <Route exact path="/GrandTotalNumberResult" component={GrandTotalNumberResult} />
              <Route exact path="/TodaySalesReport" component={TodaySalesReport} />
              <Route exact path="/SixDJackPotList" component={SixDJackPotList} />
              <Route exact path="/GrandTotalNumberResultFight" component={GrandTotalNumberResultFight} />
              <Route exact path="/DownlineReport" component={DownlineReport} />
              <Route exact path="/IndividualDownlineReport" component={IndividualDownlineReport} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/CheckNumber" component={CheckNumber} />
              <Route exact path="/UserReport" component={UserReport} />
              <Route exact path="/ShowAllSalesReportFight" component={ShowAllSalesReportFight} />
              <Route exact path="/DadingConvert" component={DadingConvert} />
              <Route exact path="/DadingConvertCSV" component={DadingConvertCSV} />
              <Route exact path="/ShowAllSalesReport"  component={ShowAllSalesReport} />
              <Route exact path= "/water"  component={ShowAllSalesReport} />
              <Route exact path="/AccountReport" component={AccountReport} />
              <Route exact path="/SimpleReport" component={SimpleReport} />
              <Route exact path="/Hit_Sales" component={Hit_Sales} />
              <Route exact path="/ActionUserRecord" component={ActionUserRecord} />
              <Route exact path="/AccountSalesList" component={AccountSalesList} />
              <Route exact path="/AddGame" component={AddGame} />
              <Route exact path="/SubAcc" component={SubAcc} />
              <Route exact path="/DadingShowAll" component={DadingShowAll} />
              <Route exact path="/GameResult" component={GameResult} />
              <Route exact path="/DisplayEditPage" component={DisplayEditPage} />
              <Route exact path="/GameSetting" component={GameSetting} />
              <Route exact path="/SendSMSGroup" component={SendSMSGroup} />
              <Route exact path="/VIPuser" component={VIPuser} />
              <Route exact path="/DetailPersonaSales" component={DetailPersonaSales} />
              <Route exact path="/SuperAdminManagePage" component={SuperAdminManagePage} />
              <Route exact path="/UpdateLatestNews" component={UpdateLatestNews} />
              <Route exact path="/CreateNews" component={CreateNews} />
              <Route exact path="/Dading" component={Dading} />
              <Route exact path="/SMSReport" component={SMSReport} />
              <Route exact path="/SMSReportDetail" component={SMSReportDetail} />
              <Route exact path="/SecondReport" component={SecondReport} />
              <Route exact path="/FirstReport" component={FirstReport} />
              <Route exact path="/ZhonglaiCalculate" component={ZhonglaiCalculate} />
              <Route exact path="/FastRegister" component={FastRegister} />
              <Route exact path="/GenerateExcel" component={GenerateExcel} />
              <Route exact path="/filter" component={FilterDading} />
              <Route exact path="/MultiNumberSearch" component={MultiNumberSearch} />
              <Route exact path="/101" component={TEMP101} />
            </Switch>
          }
          {
            this.props.Role === 'admin' && <Switch>
              {(this.props.BooleanBack === true) && <Route path="*" component={Login} />}
              <Route exact path="/" component={Login} />
              <Route exact path="/Login" component={Login} />
              <Route exact path="/betting" component={betting} />
              <Route exact path="/BettingVersion2" component={BettingVersion2} />
              <Route exact path="/TextfileConvert" component={TextfileConvert} />
              <Route exact path="/UploadCSV" component={UploadCSV} />
              <Route exact path="/Home" component={Home} />
              <Route exact path="/BetBoxVersion" component={BetBoxVersion} />
              <Route exact path="/BoxVersion" component={BoxVersion} />
              <Route exact path="/ViewReceipt" component={ViewReceipt} />
              <Route exact path="/ViewReceiptV2" component={ViewReceiptV2} />
              <Route exact path="/LongTermReceipt" component={LongTermReceipt} />
              <Route exact path="/Receipt" component={Receipt} />
              <Route exact path="/ViewResult" component={ViewResult} />
              <Route exact path="/Report" component={Report} />
              <Route exact path="/RedNumber" component={RedNumber} />
              <Route exact path="/Setting" component={Setting} />
              <Route exact path="/HR" component={HR} />
              <Route exact path="/BettingRed" component={BettingRed} />
              <Route exact path="/BettingSgd" component={BettingSgd} />
              <Route exact path="/bettingThai" component={bettingThai} />
              <Route exact path="/FindNumber" component={FindNumber} />
              <Route exact path="/GrandTotalNumberResult" component={GrandTotalNumberResult} />
              <Route exact path="/TodaySalesReport" component={TodaySalesReport} />
              <Route exact path="/SixDJackPotList" component={SixDJackPotList} />
              <Route exact path="/GrandTotalNumberResultFight" component={GrandTotalNumberResultFight} />
              <Route exact path="/DownlineReport" component={DownlineReport} />
              <Route exact path="/IndividualDownlineReport" component={IndividualDownlineReport} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/CheckNumber" component={CheckNumber} />
              <Route exact path="/UserReport" component={UserReport} />
              <Route exact path="/ShowAllSalesReportFight" component={ShowAllSalesReportFight} />
              <Route exact path="/DadingConvert" component={DadingConvert} />
              <Route exact path="/DadingConvertCSV" component={DadingConvertCSV} />
              <Route exact path="/ShowAllSalesReport"  component={ShowAllSalesReport} />
              <Route exact path= "/water"  component={ShowAllSalesReport} />
              <Route exact path="/AccountReport" component={AccountReport} />
              <Route exact path="/SimpleReport" component={SimpleReport} />
              <Route exact path="/Hit_Sales" component={Hit_Sales} />
              <Route exact path="/ActionUserRecord" component={ActionUserRecord} />
              <Route exact path="/AccountSalesList" component={AccountSalesList} />
              <Route exact path="/AddGame" component={AddGame} />
              <Route exact path="/SubAcc" component={SubAcc} />
              <Route exact path="/DadingShowAll" component={DadingShowAll} />
              <Route exact path="/GameResult" component={GameResult} />
              <Route exact path="/DisplayEditPage" component={DisplayEditPage} />
              <Route exact path="/GameSetting" component={GameSetting} />
              <Route exact path="/SendSMSGroup" component={SendSMSGroup} />
              <Route exact path="/VIPuser" component={VIPuser} />
              <Route exact path="/DetailPersonaSales" component={DetailPersonaSales} />
              <Route exact path="/UpdateLatestNews" component={UpdateLatestNews} />
              <Route exact path="/CreateNews" component={CreateNews} />
              <Route exact path="/Dading" component={Dading} />
              <Route exact path="/SecondReport" component={SecondReport} />
              <Route exact path="/FirstReport" component={FirstReport} />
              <Route exact path="/ZhonglaiCalculate" component={ZhonglaiCalculate} />
              <Route exact path="/GenerateExcel" component={GenerateExcel} />
              <Route exact path="/filter" component={FilterDading} />
              <Route exact path="/FastRegister" component={FastRegister} />
              <Route exact path="/101" component={TEMP101} />
            </Switch>
          }
          {
            this.props.Role === 'agent' && <Switch>
              {(this.props.BooleanBack === true) && <Route path="*" component={Login} />}
              <Route exact path="/" component={Login} />
              <Route exact path="/Login" component={Login} />
              <Route exact path="/betting" component={betting} />
              <Route exact path="/BettingSgd" component={BettingSgd} />
              <Route exact path="/BettingVersion2" component={BettingVersion2} />
              <Route exact path="/TextfileConvert" component={TextfileConvert} />
              <Route exact path="/UploadCSV" component={UploadCSV} />
              <Route exact path="/Home" component={Home} />
              <Route exact path="/BetBoxVersion" component={BetBoxVersion} />
              <Route exact path="/BoxVersion" component={BoxVersion} />
              <Route exact path="/ViewReceipt" component={ViewReceipt} />
              <Route exact path="/ViewReceiptV2" component={ViewReceiptV2} />
              <Route exact path="/LongTermReceipt" component={LongTermReceipt} />
              <Route exact path="/DisplayEditPage" component={DisplayEditPage} />
              <Route exact path="/Receipt" component={Receipt} />
              <Route exact path="/IndividualDownlineReport" component={IndividualDownlineReport} />
              <Route exact path="/ViewResult" component={ViewResult} />
              <Route exact path="/Report" component={Report} />
              <Route exact path="/Setting" component={Setting} />
              <Route exact path="/HR" component={HR} />
              <Route exact path="/DetailPersonaSales" component={DetailPersonaSales} />
              <Route exact path="/DownlineReport" component={DownlineReport} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/BettingRed" component={BettingRed} />
              <Route exact path="/bettingThai" component={bettingThai} />
              <Route exact path="/FindNumber" component={FindNumber} />
              <Route exact path="/Hit_Sales" component={Hit_Sales} />
              <Route exact path="/GrandTotalNumberResult" component={GrandTotalNumberResult} />
              <Route exact path="/TodaySalesReport" component={TodaySalesReport} />
              <Route exact path="/GrandTotalNumberResultFight" component={GrandTotalNumberResultFight} />
              <Route exact path="/SimpleReport" component={SimpleReport} />
              <Route exact path="/SubAcc" component={SubAcc} />
              <Route exact path="/SecondReport" component={SecondReport} />
              <Route exact path="/FirstReport" component={FirstReport} />
              <Route exact path="/FastRegister" component={FastRegister} />
              <Route exact path="/Dading" component={Dading} />
              <Route exact path="/101" component={TEMP101} />
              {/* <Route exact path="/TestingBackblur" component={TestingBackblur} /> */}
            </Switch>
          }
          {
            this.props.Role === 'player' && <Switch>
              {(this.props.BooleanBack === true) && <Route path="*" component={Login} />}
              <Route exact path="/" component={Login} />
              <Route exact path="/Login" component={Login} />
              <Route exact path="/betting" component={betting} />
              <Route exact path="/BettingSgd" component={BettingSgd} />
              <Route exact path="/BettingVersion2" component={BettingVersion2} />
              <Route exact path="/TextfileConvert" component={TextfileConvert} />
              <Route exact path="/UploadCSV" component={UploadCSV} />
              <Route exact path="/Home" component={Home} />
              <Route exact path="/BoxVersion" component={BoxVersion} />
              <Route exact path="/ViewReceipt" component={ViewReceipt} />
              <Route exact path="/ViewReceiptV2" component={ViewReceiptV2} />
              <Route exact path="/LongTermReceipt" component={LongTermReceipt} />
              <Route exact path="/DisplayEditPage" component={DisplayEditPage} />
              <Route exact path="/Receipt" component={Receipt} />
              <Route exact path="/AccountReport" component={AccountReport} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/ViewResult" component={ViewResult} />
              <Route exact path="/IndividualDownlineReport" component={IndividualDownlineReport} />
              <Route exact path="/Setting" component={Setting} />
              <Route exact path="/HR" component={HR} />
              <Route exact path="/DetailPersonaSales" component={DetailPersonaSales} />
              <Route exact path="/FindNumber" component={FindNumber} />
              <Route exact path="/Hit_Sales" component={Hit_Sales} />
              <Route exact path="/SimpleReport" component={SimpleReport} />
              <Route exact path="/GrandTotalNumberResult" component={GrandTotalNumberResult} />
              <Route exact path="/TodaySalesReport" component={TodaySalesReport} />
              <Route exact path="/GrandTotalNumberResultFight" component={GrandTotalNumberResultFight} />
              <Route exact path="/DownlineReport" component={DownlineReport} />
              <Route exact path="/BettingRed" component={BettingRed} />
              <Route exact path="/bettingThai" component={bettingThai} />
              <Route exact path="/SubAcc" component={SubAcc} />
              <Route exact path="/Dading" component={Dading} />
              <Route exact path="/DadingConvert" component={DadingConvert} />
              <Route exact path="/DadingConvertCSV" component={DadingConvertCSV} />
              <Route exact path="/SecondReport" component={SecondReport} />
              <Route exact path="/FirstReport" component={FirstReport} />
              <Route exact path="/FastRegister" component={FastRegister} />
              <Route exact path="/101" component={TEMP101} />
            </Switch>
          }
          {
            this.props.Role === '' && <Switch>
              <Route path="*" component={Login} />
              <Route exact path="/" component={Login} />
              <Route exact path="/Login" component={Login} />
            </Switch>
          }
        </div>
      </Router>
    )
  };
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    userID: state.userID,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    BooleanBack: state.BooleanBack,
  };
}

export default connect(mapStateToProps, null)(AppFunction);
