import '../App.css';
import React, {Fragment}  from 'react';
import { language } from '../language';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'react-bootstrap';
import Header2 from '../Component/Header';
import tickicons from '../Images/tick.png';
import Table from 'react-bootstrap/Table';
import Moment from 'moment-timezone';
import { characterList, characterList2, AlphabetToNumb, availablePosition } from '../static';
import { Alert } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { GamePlatformNumb1, GamePlatformNumb2 } from '../static';
import io from 'socket.io-client';
const { base, clearData, updateGameResult, getTodayGame, getGameResult, calculateReport, updateShutdown, getConfiguration, updateAllresult, autoUpdate, validateReport } = require('../Api');
var timer

function ace4dToBtace(platform) {
  if (platform === '1') return 'MAG'
  else if (platform === '2') return 'DMC'
  else if (platform === '3') return 'TOTO'
  else if (platform === '4') return 'SG4'
  else if (platform === '5') return 'SWK'
  else if (platform === '6') return 'SBH'
  else if (platform === '7') return 'SDK'              
  else if (platform === '8') return 'GD'
  else if (platform === '9') return '9'
  return 'null'
}

// function ace4dToBtace2(platform) {
//   if (platform === '1') return 'mag'
//   else if (platform === '2') return 'dmc'
//   else if (platform === '3') return 'toto'
//   else if (platform === '4') return 'sg4'
//   else if (platform === '5') return 'swk'
//   else if (platform === '6') return 'sbh'
//   else if (platform === '7') return 'sdk'
//   else if (platform === '8') return 'bt5'
//   return 'null'
// }

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};


class GameResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      GamesDetail: [],
      GameLimitPackage: {},
      Min_Date: Moment().toDate(),
      Max_Date: Moment().toDate(),
      // Min_Date: Moment(localStorage.getItem('Min_Date')).toDate(),
      // Max_Date: Moment(localStorage.getItem('Max_Date')).toDate(),
      SpecialNumb : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      Special10Numb: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      ConsolationNumb : [14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      JackpotNumb : ['jackpot1', 'jackpot2'],
      TodayGameAvailable: [],
      Games: [],
      notificationType: 'error',
      autoUpdate: false,
      TodayDate : Moment(new Date()).format('YYYY-MM-DD'),
      TodayDateSocket : Moment(new Date()).format('YYYYMMDD'),
      GameStatus: 0,
      buttondisabled: false,
      GDisplay: '',
      notificationMessage: '',
      S_Switch: false,
      Switch: false,
      Eight_Switch: false,
      StoreObjectSocket: {},
      PopScrollBackButton: false,
      Progressbar1: 0,
      Progressbar2: 0,
      Progressbar3: 0,
      Progressbar4: 0,
      Progressbar5: 0,
      Progressbar6: 0,
      Progressbar7: 0,
      Progressbar8: 0,
      Progressbar9: 0,
      CompleteTick1: false,
      CompleteTick2: false,
      CompleteTick3: false,
      CompleteTick4: false,
      CompleteTick5: false,
      CompleteTick6: false,
      CompleteTick7: false,
      CompleteTick8: false,
    }
    this.toggleResult = this.toggleResult.bind(this);
    this.setPropsState = this.setPropsState.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.SendDataIndividual = this.SendDataIndividual.bind(this);
    this.StatusGames = this.StatusGames.bind(this);
    this.GetGameResultShow = this.GetGameResultShow.bind(this);
    this.CalcuGame = this.CalcuGame.bind(this);
    this.updateShutdownFunction = this.updateShutdownFunction.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount() {
    // this.socket2 = io('http://localhost:8003/')
    this.socket2 = io('wss://server.ace4dv2.live')
    this.socket2.on('connect', () => {
      this.socket2.on('result5DListener', (data) => {
        if (data[this.state.TodayDateSocket]) {
          const todayData = data[this.state.TodayDateSocket]
       
          this.state.GamesDetail.forEach(async (item) => {
            if (item.autoUpdate) {
              if (item.Game === '3'){
                let gameDatakey5 = {}
                let gameDatakey6 = {}
                let Boolean5 = false
                let Boolean6 = false
                if (todayData['toto5'] !== undefined){
                  gameDatakey5 = todayData['toto5']
                  Boolean5 =  true
                }
                if (todayData['toto6'] !== undefined){
                  gameDatakey6 = todayData['toto6']
                  Boolean6 =  true
                }
                
                let objectToUpdate5 = {}
                let objectToUpdate6 = {}
                let objectToUpdate = {}
                if (Array.isArray(gameDatakey5) && Boolean5) {
                  for (let i = 0; i < gameDatakey5.length; i += 1) {
                    if (gameDatakey5[i]) {
                      objectToUpdate5[`5D${i}`] = gameDatakey5[i]
                    }
                  }
                } else {
                  Object.keys(gameDatakey5).forEach((item) =>{
                    if (item !== 'drawNo') {
                      objectToUpdate5[`5D${item}`] = gameDatakey5[item]
                    }
                  })
                }
                if (Array.isArray(gameDatakey6) && Boolean6) {
                  for (let i = 0; i < gameDatakey6.length; i += 1) {
                    if (gameDatakey6[i]) {
                      objectToUpdate6[`6D${i}`] = gameDatakey6[i]
                    }
                  }
                } else {
                  Object.keys(gameDatakey6).forEach((item) =>{
                    if (item !== 'drawNo') {
                      objectToUpdate6[`6D${item}`] = gameDatakey6[item]
                    }
                  })
                }
                objectToUpdate = {...objectToUpdate5, ...objectToUpdate6}
                let StoreSocket =  {}

                Object.keys(objectToUpdate).forEach((item) =>{
                  StoreSocket[item] = objectToUpdate[item]

                })
                const Store = this.state.StoreObjectSocket
                Store[item.Game] =  {...this.state.StoreObjectSocket[item.Game], ...StoreSocket}
                this.setState({ StoreObjectSocket: Store}, async ()=>{
                  const objToUpdate= {
                    idOfGames: item.ID,
                    gameDetails: this.state.StoreObjectSocket[item.Game],
                    Game: item.Game
                  }
                  const UpdateArrayGame = await autoUpdate(objToUpdate, this.props.username, this.props.token,)
                  if (UpdateArrayGame.message === "Successfully Updated"){
                    let copiedGame = this.state.GamesDetail.slice()
                    copiedGame.forEach((e, index) =>{
                      if (e.ID ===  item.ID && e.Game === item.Game){
                        copiedGame[index] = {...this.state.GamesDetail[index], ...objectToUpdate }
                      }
                    })
                    this.setState({GamesDetail: JSON.parse(JSON.stringify(copiedGame))})
                  }
                })
              } else if (item.Game === '8'){
                const gameDatakey = todayData['bt5']
        
                if (gameDatakey) {
                
                  let objectToUpdate = {}
                  if (Array.isArray(gameDatakey)) {
                    for (let i = 0; i < gameDatakey.length; i += 1) {
                      if (gameDatakey[i]) {
                        objectToUpdate[`${i}`] = gameDatakey[i]
                      }
                    }
                  } else {
                    objectToUpdate = gameDatakey
                  }
                  let StoreSocket =  {}
  
                  Object.keys(objectToUpdate).forEach((item) =>{
                    StoreSocket[item] = objectToUpdate[item]
  
                  })
                  const Store = this.state.StoreObjectSocket
                  Store[item.Game] =  {...this.state.StoreObjectSocket[item.Game], ...StoreSocket}
                  this.setState({ StoreObjectSocket: Store}, async ()=>{
                    const objToUpdate= {
                      idOfGames: item.ID,
                      gameDetails: this.state.StoreObjectSocket[item.Game],
                      Game: item.Game
                    }
                    const UpdateArrayGame = await autoUpdate(objToUpdate, this.props.username, this.props.token,)
                    if (UpdateArrayGame.message === "Successfully Updated"){
                      let copiedGame = this.state.GamesDetail.slice()
                      copiedGame.forEach((e, index) =>{
                        if (e.ID ===  item.ID && e.Game === item.Game){
                          copiedGame[index] = {...this.state.GamesDetail[index], ...objectToUpdate }
                        }
                      })
                      this.setState({GamesDetail: JSON.parse(JSON.stringify(copiedGame))})
                    }
                  })
                }
              }
            } 
          })
        }
      })
      this.socket2.on('resultListener', (data) => {
        if (data[this.state.TodayDateSocket]) {
          const todayData = data[this.state.TodayDateSocket]
       
          this.state.GamesDetail.forEach(async (item) => {
            if (item.autoUpdate) {
              const gameData = todayData[ace4dToBtace(item.Game)]
              if (gameData) {
                let objectToUpdate = {}
                if (Array.isArray(gameData)) {
                  for (let i = 0; i < gameData.length; i += 1) {
                    if (gameData[i]) {
                      objectToUpdate[`${i}`] = gameData[i]
                    }
                  }
                } else {
                  objectToUpdate = gameData;
                  ['P1OriPosition', 'P2OriPosition', 'P3OriPosition'].forEach((item) =>{
                    if ( gameData[item] !== undefined){
                      objectToUpdate[item] = characterList[gameData[item]]
                    }
                  })
                }
                let StoreSocket =  {}

                Object.keys(objectToUpdate).forEach((item) =>{
                  StoreSocket[item] = objectToUpdate[item]

                })
                const Store = this.state.StoreObjectSocket
                Store[item.Game] =  {...this.state.StoreObjectSocket[item.Game], ...StoreSocket}
                this.setState({ StoreObjectSocket: Store}, async ()=>{
                  const objToUpdate= {
                    idOfGames: item.ID,
                    gameDetails: this.state.StoreObjectSocket[item.Game],
                    Game: item.Game
                  }
                  const UpdateArrayGame = await autoUpdate(objToUpdate, this.props.username, this.props.token,)
                  if (UpdateArrayGame.message === "Successfully Updated"){
                    let copiedGame = this.state.GamesDetail.slice()
                    copiedGame.forEach((e, index) =>{
                      if (e.ID ===  item.ID && e.Game === item.Game){
                        copiedGame[index] = {...this.state.GamesDetail[index], ...objectToUpdate }
                      }
                    })
                    this.setState({GamesDetail: JSON.parse(JSON.stringify(copiedGame))})
                  }
                })
              }
            }
          })
        }
      })
    })
    this.socket = io(`${base}`)
    this.socket.on('connect', () => {
      this.socket.on('progressBarListener', (data) => {
        if (data.progressPercent === '100') {
          this.setState({ [`Progressbar${data.platform}`]: data.progressPercent }, () => {
            setTimeout(function(){
              this.setState({ [`Progressbar${data.platform}`]: 0, [`CompleteTick${data.platform}`]: true });
            }.bind(this),10000);
          })
        } else {
          this.setState({ [`Progressbar${data.platform}`]: data.progressPercent })
        }
      })
    })
    this.CallAPI();
    this.SetUsersAuthorities();
  }

  // clearDatapercent(platform) {
  //   console.log('platform', platform)
  //   timer = setTimeout(() => {
  //     this.setState({ [`Progressbar${platform}`]: 0 }, () => {
  //       console.log(this.state.Progressbar1)
  //     })
  //   }, 5000);
  // }

  // async RunProgress() {
  //   const getProgress = await executeProgress(this.props.username, this.props.token)
  //   if (getProgress.message === 'Finished') {
  //     timer = setTimeout(() => {
  //       this.setState({ Progressbar1: 0, Progressbar2: 0, Progressbar3: 0, Progressbar4: 0, Progressbar5: 0, Progressbar6: 0, Progressbar7: 0, Progressbar8: 0 })
  //     }, 10000);
  //   }
  // }

  componentWillUnmount() {
    this.socket2 && this.socket2.disconnect();
    this.socket && this.socket.disconnect();
  }

  async CallAPI(){
    const UpdateArrayGame = await getConfiguration(this.props.token, this.props.username)
    this.setState({S_Switch: (UpdateArrayGame[0].S_ShutDown === 1) ? true : false, Switch: (UpdateArrayGame[0].ShutDown === 1) ? true : false, Eight_Switch: (UpdateArrayGame[0].EightShutDown === 1) ? true : false, })

    if ( ( Moment(this.state.Max_Date).format('YYYY-MM-DD')) > Moment(this.state.Min_Date).format('YYYY-MM-DD')){
      this.setState({ notificationType: 'error' }, ()=>{
        this.DateopenNotification(`${language[this.props.currentLanguage].FormDateError}`)
      });
    } else {
      const GDisplay = localStorage.getItem('GameDisplay');
      const TodayGameAvailable = await getTodayGame( this.state.Max_Date, this.state.Min_Date, this.props.token, this.props.username)
      // console.log('TodayGameAvailable', TodayGameAvailable)
      if (TodayGameAvailable.message === 'No Games') {
        this.setState({ TodayGameAvailable: [], GDisplay})
      } else {
        let ReplaceData = []
        let BooleanHaveData = false
        this.state.TodayGameAvailable.forEach((item) =>{
          if (item.ButtonBoolean !== undefined){
            ReplaceData.push(item)
            BooleanHaveData = true
          }
        })
        if (BooleanHaveData === true) {
          let array = [...TodayGameAvailable];
          TodayGameAvailable.forEach((item, index) =>{
            this.state.GamesDetail.forEach((Checkitem, Checkindex) =>{
              if (item.ID === Checkitem.ID && item.Game === Checkitem.Game){
                array[index] = {...this.state.GamesDetail[Checkindex], ButtonBoolean: true }
              }
            })
          })
          this.setState({ TodayGameAvailable: JSON.parse(JSON.stringify(array)), GDisplay})
        } else {
          this.setState({ TodayGameAvailable, GDisplay})
        }
      }
    }
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  toggleResult(platform) {
    this.setState({
      [`show${platform}`]: !this.state[`show${platform}`],
    })
  }

  async gameChangeDetail(Package, game, index) {
    if (index){
      const newIds = this.state.TodayGameAvailable //copy the array
      newIds[index - 1] = (typeof newIds[index - 1].ButtonBoolean === 'undefined') ? {...this.state.TodayGameAvailable[index - 1], ButtonBoolean: true } : (newIds[index - 1].ButtonBoolean === true)? {...this.state.TodayGameAvailable[index - 1], ButtonBoolean: false } : {...this.state.TodayGameAvailable[index - 1], ButtonBoolean: true } //execute the manipulations
      this.setState({TodayGameAvailable: newIds}) //set the new state
    }
    const DisplayGame = await getGameResult(Package.ID, Package.Game, this.props.token ,this.props.username)
    
    if (DisplayGame.message !== 'No result') {
   
      let UnwantedArray = [];
      let WantedArray = [];
      if (game === 'UpdateResult') {
        this.state.GamesDetail.forEach((item, indexs)=>{
          if ((item.ID === DisplayGame.ID) && (item.Game === DisplayGame.Game) ) {
            const disabledbutton = `disabledbutton${indexs}`
            this.setState({[disabledbutton]: false})
            UnwantedArray.push(item)
          } else {
            WantedArray.push(item)
          }
        })

        this.setState({ GamesDetail: [...WantedArray, {
          ID: DisplayGame.ID,
          Game: DisplayGame.Game,
          Date: DisplayGame.Date,
          '5D1': DisplayGame['5D1'] || '',
          '5D2': DisplayGame['5D2'] || '',
          '5D3': DisplayGame['5D3'] || '',
          '6D1': DisplayGame['6D1'] || '',
          '7D1': DisplayGame['7D1'] || '',
          '1': DisplayGame['1'] || '',
          '2': DisplayGame['2'] || '',
          '3': DisplayGame['3'] || '',
          '4': DisplayGame['4'] || '',
          '5': DisplayGame['5'] || '',
          '6': DisplayGame['6'] || '',
          '7': DisplayGame['7'] || '',
          '8': DisplayGame['8'] || '',
          '9': DisplayGame['9'] || '',
          '10': DisplayGame['10'] || '',
          '11': DisplayGame['11'] || '',
          '12': DisplayGame['12'] || '',
          '13': DisplayGame['13'] || '',
          '14': DisplayGame['14'] || '',
          '15': DisplayGame['15'] || '',
          '16': DisplayGame['16'] || '',
          '17': DisplayGame['17'] || '',
          '18': DisplayGame['18'] || '',
          '19': DisplayGame['19'] || '',
          '20': DisplayGame['20'] || '',
          '21': DisplayGame['21'] || '',
          '22': DisplayGame['22'] || '',
          '23': DisplayGame['23'] || '',
          P1OriPosition: DisplayGame.P1OriPosition || '',
          P2OriPosition: DisplayGame.P2OriPosition || '',
          P3OriPosition: DisplayGame.P3OriPosition || '',
          P1: DisplayGame.P1 || '',
          P2: DisplayGame.P2 || '',
          P3: DisplayGame.P3 || '',
          ['Calculated' + DisplayGame.Game]: DisplayGame['Calculated' + DisplayGame.Game],
        }]})
      } else {
        if (this.state.GamesDetail.find((item) => (item.ID === DisplayGame.ID) && (item.Game === DisplayGame.Game))) {
          this.state.GamesDetail.forEach((item)=>{
            if ((item.ID === DisplayGame.ID) && (item.Game === DisplayGame.Game) ) {
              UnwantedArray.push(item)
            } else {
              WantedArray.push(item)
            }
          })
          this.setState({ GamesDetail: JSON.parse(JSON.stringify(WantedArray))});
        } else {
          this.setState({ GamesDetail: [...this.state.GamesDetail, {
            ID: DisplayGame.ID,
            Game: DisplayGame.Game,
            Date: DisplayGame.Date,
            '5D1': DisplayGame['5D1'] || '',
            '5D2': DisplayGame['5D2'] || '',
            '5D3': DisplayGame['5D3'] || '',
            '6D1': DisplayGame['6D1'] || '',
            '7D1': DisplayGame['7D1'] || '',
            '1': DisplayGame['1'] || '',
            '2': DisplayGame['2'] || '',
            '3': DisplayGame['3'] || '',
            '4': DisplayGame['4'] || '',
            '5': DisplayGame['5'] || '',
            '6': DisplayGame['6'] || '',
            '7': DisplayGame['7'] || '',
            '8': DisplayGame['8'] || '',
            '9': DisplayGame['9'] || '',
            '10': DisplayGame['10'] || '',
            '11': DisplayGame['11'] || '',
            '12': DisplayGame['12'] || '',
            '13': DisplayGame['13'] || '',
            '14': DisplayGame['14'] || '',
            '15': DisplayGame['15'] || '',
            '16': DisplayGame['16'] || '',
            '17': DisplayGame['17'] || '',
            '18': DisplayGame['18'] || '',
            '19': DisplayGame['19'] || '',
            '20': DisplayGame['20'] || '',
            '21': DisplayGame['21'] || '',
            '22': DisplayGame['22'] || '',
            '23': DisplayGame['23'] || '',
            P1OriPosition: DisplayGame.P1OriPosition || '',
            P2OriPosition: DisplayGame.P2OriPosition || '',
            P3OriPosition: DisplayGame.P3OriPosition || '',
            P1: DisplayGame.P1 || '',
            P2: DisplayGame.P2 || '',
            P3: DisplayGame.P3 || '',
            ['Calculated' + DisplayGame.Game]: DisplayGame['Calculated' + DisplayGame.Game],
          }]}, ()=>{
            Object.keys(this.state.GamesDetail).forEach((item) =>{
              const disableds = `disabled${item}`
              const disabledbutton = `disabledbutton${item}`
              this.setState({ [disableds]: false, [disabledbutton]: false})
            })
          })
        }
      }
    } else if ( DisplayGame.message === 'No result'){
      let UnwantedArray = [];
      let WantedArray = [];
      if (this.state.GamesDetail.find((item) => (item.ID === Package.ID) && (item.Game === Package.Game))) {
        
        this.state.GamesDetail.forEach((item)=>{
          if ((item.ID === Package.ID) && (item.Game === Package.Game) ) {
            UnwantedArray.push(item)
          } else {
            WantedArray.push(item)
          }
        })
        this.setState({ GamesDetail: JSON.parse(JSON.stringify(WantedArray))});
      } else {
        this.setState({ GamesDetail: [...this.state.GamesDetail, {
          ID: Package.ID || '',
          Game: Package.Game || '',
          Date: Package.Date || '',
          '5D1': Package['5D1'] || '',
          '5D2': Package['5D2'] || '',
          '5D3': Package['5D3'] || '',
          '6D1': Package['6D1'] || '',
          '7D1': Package['7D1'] || '',
          '1': Package['1'] || '',
          '2': Package['2'] || '',
          '3': Package['3'] || '',
          '4': Package['4'] || '',
          '5': Package['5'] || '',
          '6': Package['6'] || '',
          '7': Package['7'] || '',
          '8': Package['8'] || '',
          '9': Package['9'] || '',
          '10': Package['10'] || '',
          '11': Package['11'] || '',
          '12': Package['12'] || '',
          '13': Package['13'] || '',
          '14': Package['14'] || '',
          '15': Package['15'] || '',
          '16': Package['16'] || '',
          '17': Package['17'] || '',
          '18': Package['18'] || '',
          '19': Package['19'] || '',
          '20': Package['20'] || '',
          '21': Package['21'] || '',
          '22': Package['22'] || '',
          '23': Package['23'] || '',
          P1OriPosition: Package.P1OriPosition || '',
          P2OriPosition: Package.P2OriPosition || '',
          P3OriPosition: Package.P3OriPosition || '',
          P1: Package.P1 || '',
          P2: Package.P2 || '',
          P3: Package.P3 || '',
          ['Calculated' + Package.Game]: Package['Calculated' + Package.Game],
        }]})
      }
    }
  }

  async revalidateReport(date, platform, idGames, Package) {
    for (let i = 0; i < this.state.GamesDetail.length; i++) {
      // timer = setTimeout(() => {
      //   this.RunthisFunction2(date, platform, idGames, Package)
      // }, 5000);
      setTimeout(function(){
        this.RunthisFunction2(date, platform, idGames, Package)
      }.bind(this),5000);
    }
    // rechecktimer = setInterval(async () => {
    //   console.log('GamesDetail', this.state.GamesDetail)
    //   const recheckresult = await validateReport(this.props.username, this.props.token, date, platform)
    //   if (recheckresult.message === 'Loading') {
    //   // this.openNotification('Success')
    //   } 
    //   if (recheckresult.message === 'Done') {
    //     this.setState({ notificationType: 'success' }, ()=>{
    //       clearInterval(rechecktimer);
    //       console.log('hiii hiii hii hiii hiii hiii hiii hiii')
    //       if (idGames > -1) {
    //         const newIds = this.state.TodayGameAvailable
    //         const newIds2 = this.state.GamesDetail
    //         newIds[idGames] = (typeof newIds[idGames].ButtonBoolean === 'undefined') ? {...this.state.TodayGameAvailable[idGames], ButtonBoolean: true } : (newIds[idGames].ButtonBoolean === true)? {...this.state.TodayGameAvailable[idGames], ButtonBoolean: false } : {...this.state.TodayGameAvailable[idGames], ButtonBoolean: false } //execute the manipulations
    //         newIds2[idGames] = (typeof newIds2[idGames].autoUpdate === 'undefined') ? {...this.state.GamesDetail[idGames], autoUpdate: true } : (newIds2[idGames].autoUpdate === true)? {...this.state.GamesDetail[idGames], autoUpdate: false } : {...this.state.GamesDetail[idGames], autoUpdate: false } //execute the manipulations
    //         this.setState({ GamesDetail: newIds2 })
    //       }
    //       this.openNotification(`${language[this.props.currentLanguage].Success}`);
    //       this.CallAPI();
    //       this.gameChangeDetail(Package, 'UpdateResult');
    //     })
    //   }
    // }, 5000);
  }

  async RunthisFunction2(date, platform, idGames, Package) {
    const recheckresult = await validateReport(this.props.username, this.props.token, date, platform)
    if (recheckresult.message === 'Loading') {
    // this.openNotification('Success')
    }
    if (recheckresult.message === 'Done') {
      this.setState({ notificationType: 'success' }, ()=>{
        if (idGames > -1) {
          const newIds = this.state.TodayGameAvailable
          const newIds2 = this.state.GamesDetail
          newIds[idGames] = (typeof newIds[idGames].ButtonBoolean === 'undefined') ? {...this.state.TodayGameAvailable[idGames], ButtonBoolean: true } : (newIds[idGames].ButtonBoolean === true)? {...this.state.TodayGameAvailable[idGames], ButtonBoolean: false } : {...this.state.TodayGameAvailable[idGames], ButtonBoolean: false } //execute the manipulations
          newIds2[idGames] = (typeof newIds2[idGames].autoUpdate === 'undefined') ? {...this.state.GamesDetail[idGames], autoUpdate: true } : (newIds2[idGames].autoUpdate === true)? {...this.state.GamesDetail[idGames], autoUpdate: false } : {...this.state.GamesDetail[idGames], autoUpdate: false } //execute the manipulations
          this.setState({ GamesDetail: newIds2 })
        }
        this.openNotification(`${language[this.props.currentLanguage].Success}`);
        // this.CallAPI();
        this.gameChangeDetail(Package, 'UpdateResult');
      })
    }
  }

  async StatusGames(idGames, Game){
    let copiedGame = this.state.TodayGameAvailable
    copiedGame.forEach((e, index) =>{
      if (e.ID === idGames && e.Game === Game){
        copiedGame[index] = ((copiedGame[index] && copiedGame[index].autoUpdate) === undefined) ? {...this.state.TodayGameAvailable[index], autoUpdate: true } : {...this.state.TodayGameAvailable[index], autoUpdate: !copiedGame[index].autoUpdate}
      }
    })
    this.setState({ TodayGameAvailable: JSON.parse(JSON.stringify(copiedGame)) })

    let copiedGameList = this.state.GamesDetail
    copiedGameList.forEach((e, index) =>{
      if (e.ID === idGames && e.Game === Game){
        copiedGameList[index] = ((copiedGameList[index] && copiedGameList[index].autoUpdate) === undefined) ? {...this.state.GamesDetail[index], autoUpdate: true } : {...this.state.GamesDetail[index], autoUpdate: !copiedGameList[index].autoUpdate}
      }
    })

    this.setState({ GamesDetail: JSON.parse(JSON.stringify(copiedGameList)) })
  }

  async CalcuGame(GameID, TypeGame, Package, idGames){
    let gotError = false
    let typeOfError = ''
    let disabledbutton = `disabledbutton${idGames}`
    this.setState({[disabledbutton]: true}, async ()=>{
      
      if (TypeGame === '2' || TypeGame === '4' || TypeGame === '5' || TypeGame === '6' || TypeGame === '7'){
        for (let i = 0; i < availablePosition.length; i += 1) {
          if (availablePosition[i] === 'P1' || availablePosition[i] === 'P2' || availablePosition[i] === 'P3' ) {
            if (this.state.GamesDetail[idGames][AlphabetToNumb[availablePosition[i]]].length === 0) {
              typeOfError +=  `${availablePosition[i]}  ${language[this.props.currentLanguage].must4digit} `
              gotError = true
            }
          }
        }
      } else {
        for (let i = 0; i < availablePosition.length; i += 1) {
          if (availablePosition[i] === 'P1' || availablePosition[i] === 'P2' || availablePosition[i] === 'P3' ) {
            if (this.state.GamesDetail[idGames][AlphabetToNumb[availablePosition[i]]].length === 0) {
              typeOfError +=  `${availablePosition[i]}  ${language[this.props.currentLanguage].must4digit} `
              gotError = true
            }
          }
        }
      }
      if (gotError) {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(typeOfError, idGames)
        });
      } else {
        const DisplayReport = await calculateReport( Moment(Package.Date).format('YYYY-MM-DD'), TypeGame, this.props.username ,this.props.token)
        console.log('DisplayReport', DisplayReport)
        if (DisplayReport.message === "success"){
          this.revalidateReport(Moment(Package.Date).format('YYYY-MM-DD'), TypeGame, idGames, Package)
        } else {
          this.setState({ notificationType: 'error' }, ()=>{
            this.openNotification(DisplayReport.error, idGames);
          })
        }
      }  
    })

    // const Platform = {
    //   Date: DisplayGame.Date,
    //   Platform: DisplayGame.Game
    // }
    // Success
    //No Result  Game Result haven't Ended Yet
    // Data Error  Data Error 请联系管理员 !!
    // }

  }

  setPropsState(game, subfield, value, ID) {

   
    let gameObjectrEdit = this.state.GamesDetail
    const CheckValue = (value).replace(/[^a-zA-Z ]/g, "");
    const CheckValueNumb = (value).replace(/\D+/g, '');
    if (subfield === 'P1OriPosition' || subfield === 'P2OriPosition' || subfield === 'P3OriPosition') {
      // const gameObjectrEdit = this.state.GamesDetail
      gameObjectrEdit[ID][subfield] = (characterList2[CheckValue] || '')
      // this.setState({ GamesDetail: gameObjectrEdit})
    } else {
      // const gameObjectrEdit = this.state.GamesDetail
      gameObjectrEdit[ID][subfield] = CheckValueNumb
      // this.setState({ GamesDetail: gameObjectrEdit})
    }
    // if (position) {
    //   if (position === 'P1OriPosition' || position === 'P2OriPosition' || position === 'P3OriPosition') {
    //     gameObjectrEdit[posGame][position] = value ? value : ''
    //   } else if (position.includes('Limit')) {
    //     gameObjectrEdit[posGame][position] = value ? value.replace(/[^0-9-]/g,'') : ''
    //   } else if (position === 'PrizeGiven') {
    //     gameObjectrEdit[posGame][position] = value
    //   } else {
    //     gameObjectrEdit[posGame][position] = value ? value.replace(/[^0-9-]/g,'').substring(0, 4) : ''
    //   }
    // }
    // if (updateAllObject) {
    //   // const gameObjectrEdit = this.state.GamesDetail
    //   for (let i = 0; i < availablePosition.length; i += 1) {
    //     gameObjectrEdit[posGame][availablePosition[i]] = updateAllObject[availablePosition[i]]
    //   }
    // }
    this.setState({ GamesDetail: JSON.parse(JSON.stringify(gameObjectrEdit))})
  }

  onKeyPress(e,  Game, Status, item, GameKey, platformKey){
    if (e.key === '+') {
      this[Game + Status].focus();
      e.preventDefault();
    } else if (e.key === 'Enter') {
      const disableds = `disabled${GameKey}${platformKey}`
      this.setState({ [disableds]: true }, ()=>{
        this.SendDataIndividual(item, Game, GameKey, platformKey)
      })
      // this.updateGame();
    }
  }

  async SendDataIndividual(item, Game, GameKey, platformKey){
  
    if (characterList2[item] === 'P1OriPosition' || characterList2[item] === 'P2OriPosition' || characterList2[item] === 'P3OriPosition') {
      if ( this.state.GamesDetail[GameKey][characterList2[item]] !== undefined) {
        if (this.state.GamesDetail[GameKey][characterList2[item]].length !== 1 && this.state.GamesDetail[GameKey][characterList2[item]].length === 0) {
          this.setState({ notificationType: 'error' }, ()=>{
            this.openNotification(language[this.props.currentLanguage].Positionmustbe1character)
          })
        } else {
          if (this.state.GamesDetail[GameKey]['Calculated' + Game] === 1 && this.props.Role === "superAdmin"){
            const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
            const UserID = this.state.GamesDetail[GameKey].ID;
            const objToUpdate = {
              idOfGames: UserID,
              Position: characterList2[item],
              Value: this.state.GamesDetail[GameKey][characterList2[item]],
              Games: Game,
              Date: FilterDate,
            }
            const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
            const objToUpdate2 = {
              idOfGames: UserID,
              Position: characterList2[item].substring(0, 2),
              Value: this.state.GamesDetail[GameKey][AlphabetToNumb[this.state.GamesDetail[GameKey][characterList2[item]]]],
              Games: Game,
              Date: FilterDate,
            }
            await updateGameResult(objToUpdate2, this.props.token ,this.props.username)
            const objToUpdate3 = {
              idOfGames: UserID,
              Position: AlphabetToNumb[this.state.GamesDetail[GameKey][item]],
              Value: '----',
              Games: Game,
              Date: FilterDate,
            }
            await updateGameResult(objToUpdate3, this.props.token ,this.props.username)

            if (GameCheck.message === 'Successfully Updated') {
              const disableds = `disabled${GameKey}${platformKey}`
              this.setState({ notificationType: 'success', [disableds]: false }, ()=>{
                this.GetGameResultShow(UserID, Game);
                this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
              })
            } else {
              const disableds = `disabled${GameKey}${platformKey}`
              this.setState({[disableds]: false})
            }
          } else if(this.state.GamesDetail[GameKey]['Calculated' + Game] === 0) {
            const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
            const UserID = this.state.GamesDetail[GameKey].ID;
            const objToUpdate = {
              idOfGames: UserID,
              Position: characterList2[item],
              Value: this.state.GamesDetail[GameKey][characterList2[item]],
              Games: Game,
              Date: FilterDate,
            }
            const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
            const objToUpdate2 = {
              idOfGames: UserID,
              Position: characterList2[item].substring(0, 2),
              Value: this.state.GamesDetail[GameKey][AlphabetToNumb[this.state.GamesDetail[GameKey][characterList2[item]]]],
              Games: Game,
              Date: FilterDate,
            }
            await updateGameResult(objToUpdate2, this.props.token ,this.props.username)
            const objToUpdate3 = {
              idOfGames: UserID,
              Position: AlphabetToNumb[this.state.GamesDetail[GameKey][item]],
              Value: '----',
              Games: Game,
              Date: FilterDate,
            }
            await updateGameResult(objToUpdate3, this.props.token ,this.props.username)
            if (GameCheck.message === 'Successfully Updated') {
              this.setState({ notificationType: 'success' }, ()=>{
                this.GetGameResultShow(UserID, Game);
                this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
              })
            } else {
              const disableds = `disabled${GameKey}${platformKey}`
              this.setState({[disableds]: false})
            }
          } else {
            this.setState({ notificationType: 'error' }, ()=>{
              this.openNotification(`${language[this.props.currentLanguage].CannotRecalculate}`, GameKey);
            })
          }

          // const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY/MM/DD');
          // const UserID = this.state.GamesDetail[GameKey].ID;
          // const objToUpdate = {
          //   idOfGames: UserID,
          //   Position: characterList2[item],
          //   Value: this.state.GamesDetail[GameKey][characterList2[item]],
          //   Games: Game,
          //   Date: FilterDate,
          // }
          // const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          // const objToUpdate2 = {
          //   idOfGames: UserID,
          //   Position: characterList2[item].substring(0, 2),
          //   Value: this.state.GamesDetail[GameKey][AlphabetToNumb[this.state.GamesDetail[GameKey][characterList2[item]]]],
          //   Games: Game,
          //   Date: FilterDate,
          // }
          // await updateGameResult(objToUpdate2, this.props.token ,this.props.username)
          // const objToUpdate3 = {
          //   idOfGames: UserID,
          //   Position: AlphabetToNumb[this.state.GamesDetail[GameKey][item]],
          //   Value: '----',
          //   Games: Game,
          //   Date: FilterDate,
          // }
          // await updateGameResult(objToUpdate3, this.props.token ,this.props.username)
          // if (GameCheck.message === 'Successfully Updated') {
          //   this.setState({ notificationType: 'success' }, ()=>{
          //     this.GetGameResultShow(UserID, Game);
          //     this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
          //   })
          // } else {
          //   const disableds = `disabled${GameKey}`
          //   this.setState({[disableds]: false})
          // }
        }
      } else {
        this.setState({ notificationType: 'error' }, ()=>{
          this.openNotification(language[this.props.currentLanguage].Positionmustbe1character, GameKey);
        })
      }
    } else if (item === '5D1' || item === '5D2' || item === '5D3' ){
      if (this.state.GamesDetail[GameKey][characterList2[item]].length > 4 && this.state.GamesDetail[GameKey][characterList2[item]].length !== 0 && this.state.GamesDetail[GameKey][characterList2[item]].length < 8) {
        if (this.state.GamesDetail[GameKey]['Calculated' + Game] === 1 && this.props.Role === "superAdmin"){
          const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
          const UserID = this.state.GamesDetail[GameKey].ID;
          const objToUpdate = {
            idOfGames: UserID,
            Position: characterList2[item],
            Value: this.state.GamesDetail[GameKey][characterList2[item]],
            Games: Game,
            Date: FilterDate,
          }
          const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          if (GameCheck.message === 'Successfully Updated') {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({ notificationType: 'success',[disableds]: false }, ()=>{
              this.GetGameResultShow(UserID, Game);
              this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
            })
          } else {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({[disableds]: false})
          }
        } else if(this.state.GamesDetail[GameKey]['Calculated' + Game] === 0) {
          const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
          const UserID = this.state.GamesDetail[GameKey].ID;
          const objToUpdate = {
            idOfGames: UserID,
            Position: characterList2[item],
            Value: this.state.GamesDetail[GameKey][characterList2[item]],
            Games: Game,
            Date: FilterDate,
          }
          const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          if (GameCheck.message === 'Successfully Updated') {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({ notificationType: 'success',[disableds]: false }, ()=>{
              this.GetGameResultShow(UserID, Game);
              this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
            })
          } else {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({[disableds]: false})
          }
        } else {
          const disableds = `disabled${GameKey}${platformKey}`
          this.setState({ notificationType: 'error',[disableds]: false }, ()=>{
            this.openNotification(`${language[this.props.currentLanguage].CannotRecalculate}`, GameKey);
          })
        }
      } else {
        const disableds = `disabled${GameKey}${platformKey}`
        this.setState({ notificationType: 'error',[disableds]: false }, ()=>{
          this.openNotification(`${language[this.props.currentLanguage].Number5digit}`);
        })
      }
    } else if ( item === '6D1' ){
      if (this.state.GamesDetail[GameKey][characterList2[item]].length > 5 && this.state.GamesDetail[GameKey][characterList2[item]].length !== 0 && this.state.GamesDetail[GameKey][characterList2[item]].length < 8) {
      
        if (this.state.GamesDetail[GameKey]['Calculated' + Game] === 1 && this.props.Role === "superAdmin"){
          const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
          const UserID = this.state.GamesDetail[GameKey].ID;
          const objToUpdate = {
            idOfGames: UserID,
            Position: characterList2[item],
            Value: this.state.GamesDetail[GameKey][characterList2[item]],
            Games: Game,
            Date: FilterDate,
          }
          const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          if (GameCheck.message === 'Successfully Updated') {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({ notificationType: 'success',[disableds]: false }, ()=>{
              this.GetGameResultShow(UserID, Game);
              this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
            })
          } else {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({[disableds]: false})
          }
        } else if(this.state.GamesDetail[GameKey]['Calculated' + Game] === 0) {
          const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
          const UserID = this.state.GamesDetail[GameKey].ID;
          const objToUpdate = {
            idOfGames: UserID,
            Position: characterList2[item],
            Value: this.state.GamesDetail[GameKey][characterList2[item]],
            Games: Game,
            Date: FilterDate,
          }
          
          const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          if (GameCheck.message === 'Successfully Updated') {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({ notificationType: 'success',[disableds]: false }, ()=>{
              this.GetGameResultShow(UserID, Game);
              this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
            })
          } else {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({[disableds]: false})
          }
        } else {
          const disableds = `disabled${GameKey}${platformKey}`
          this.setState({ notificationType: 'error',[disableds]: false }, ()=>{
            this.openNotification(`${language[this.props.currentLanguage].CannotRecalculate}`, GameKey);
          })
        }
      } else {
        const disableds = `disabled${GameKey}${platformKey}`
        this.setState({ notificationType: 'error',[disableds]: false }, ()=>{
          this.openNotification(`${language[this.props.currentLanguage].Number6digit}`, GameKey);
        })
      }
    } else if ( item === '7D1' ){
      
      if (this.state.GamesDetail[GameKey][characterList2[item]].length > 6 && this.state.GamesDetail[GameKey][characterList2[item]].length !== 0 && this.state.GamesDetail[GameKey][characterList2[item]].length < 8) {
        if (this.state.GamesDetail[GameKey]['Calculated' + Game] === 1 && this.props.Role === "superAdmin"){
       
          const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
          const UserID = this.state.GamesDetail[GameKey].ID;
          const objToUpdate = {
            idOfGames: UserID,
            Position: characterList2[item],
            Value: this.state.GamesDetail[GameKey][characterList2[item]],
            Games: Game,
            Date: FilterDate,
          }
        
          const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          
          if (GameCheck.message === 'Successfully Updated') {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({ notificationType: 'success',[disableds]: false }, ()=>{
              this.GetGameResultShow(UserID, Game);
              this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
            })
          } else {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({[disableds]: false})
          }
        } else if(this.state.GamesDetail[GameKey]['Calculated' + Game] === 0) {
          const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
          const UserID = this.state.GamesDetail[GameKey].ID;
          const objToUpdate = {
            idOfGames: UserID,
            Position: characterList2[item],
            Value: this.state.GamesDetail[GameKey][characterList2[item]],
            Games: Game,
            Date: FilterDate,
          }
         
          const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          
          if (GameCheck.message === 'Successfully Updated') {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({ notificationType: 'success',[disableds]: false }, ()=>{
              this.GetGameResultShow(UserID, Game);
              this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
            })
          } else {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({[disableds]: false})
          }
        } else {
          const disableds = `disabled${GameKey}${platformKey}`
          this.setState({ notificationType: 'error',[disableds]: false }, ()=>{
            this.openNotification(`${language[this.props.currentLanguage].CannotRecalculate}`, GameKey);
          })
        }
      } else {
        const disableds = `disabled${GameKey}${platformKey}`
        this.setState({ notificationType: 'error',[disableds]: false }, ()=>{
          this.openNotification(`${language[this.props.currentLanguage].Number7digit}`);
        })
      }
    } else {
      if (this.state.GamesDetail[GameKey][AlphabetToNumb[characterList2[item]]].length === 4 && this.state.GamesDetail[GameKey][AlphabetToNumb[characterList2[item]]].length !== 0 && this.state.GamesDetail[GameKey][AlphabetToNumb[characterList2[item]]].length > 3) {
        if (this.state.GamesDetail[GameKey]['Calculated' + Game] === 1 && this.props.Role === "superAdmin"){
          const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
          const UserID = this.state.GamesDetail[GameKey].ID;
          const objToUpdate = {
            idOfGames: UserID,
            Position: AlphabetToNumb[characterList2[item]],
            Value: this.state.GamesDetail[GameKey][AlphabetToNumb[characterList2[item]]],
            Games: Game,
            Date: FilterDate,
          }
          const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          if (GameCheck.message === 'Successfully Updated') {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({ notificationType: 'success',[disableds]: false  }, ()=>{
              this.GetGameResultShow(UserID, Game);
              this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
            })
          } else {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({[disableds]: false})
          }
        } else if(this.state.GamesDetail[GameKey]['Calculated' + Game] === 0) {
          const FilterDate = Moment(this.state.GamesDetail[GameKey].Date).format('YYYY-MM-DD');
          const UserID = this.state.GamesDetail[GameKey].ID;
          const objToUpdate = {
            idOfGames: UserID,
            Position: AlphabetToNumb[characterList2[item]],
            Value: this.state.GamesDetail[GameKey][AlphabetToNumb[characterList2[item]]],
            Games: Game,
            Date: FilterDate,
          }
       
          const GameCheck = await updateGameResult(objToUpdate, this.props.token ,this.props.username)
          
          if (GameCheck.message === 'Successfully Updated') {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({ notificationType: 'success',[disableds]: false }, ()=>{
              this.GetGameResultShow(UserID, Game);
              this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`, GameKey);
            })
          } else {
            const disableds = `disabled${GameKey}${platformKey}`
            this.setState({[disableds]: false})
          }
        } else {
          
          const disableds = `disabled${GameKey}${platformKey}`
          this.setState({ notificationType: 'error',[disableds]: false }, ()=>{
            this.openNotification(`${language[this.props.currentLanguage].CannotRecalculate}`, GameKey);
          })
        }
      } else {
        
        const disableds = `disabled${GameKey}${platformKey}`
        this.setState({ notificationType: 'error',[disableds]: false }, ()=>{
          this.openNotification(`${language[this.props.currentLanguage].Number4digit}`, GameKey);
        })
      }
    }
  }
  async GetGameResultShow(idOfGames, Game){
    const DisplayGame = await getGameResult(idOfGames, Game, this.props.token ,this.props.username)
    let Key = '';
    this.state.GamesDetail.forEach((item, index) =>{
      if (item.Game === Game && item.ID === idOfGames) {
        Key = index
      }
    })
    const newGameLimitPackage = this.state.GamesDetail
    newGameLimitPackage[Key] = {...this.state.GamesDetail[Key], ...DisplayGame};
    this.setState({GamesDetail: JSON.parse(JSON.stringify(newGameLimitPackage))})
    
  }
  
  async setSelectedDate(Min_Date) {
    this.setState({ Min_Date }, ()=>{
      this.props.setMinDate(Min_Date);
      // this.CallAPI();
    })
  }

  async MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date }, ()=>{
      this.props.setMaxDate(Max_Date)
      // this.CallAPI();
    })
  }

  async clearDataF() {
    const result = await clearData(this.props.username, this.props.token )
    console.log(result)
    if (result.message === 'Successfully Updated') {
      this.setState({ notificationType: 'success' }, ()=>{
        this.openNotification(`已清除`);
      })
    } else {
      this.setState({ notificationType: 'error' }, ()=>{
        this.openNotification(`清除有问题`);
      })
    }
  }

  async updateShutdownFunction(){
    const updateShutdownresult = await updateShutdown(this.state.Switch, this.state.S_Switch, this.state.Eight_Switch, this.props.username, this.props.token )
    if (updateShutdownresult.message === 'Successful Update Configuration Shutdown'){
      this.setState({ notificationType: 'success' }, ()=>{
        this.openNotification(`${language[this.props.currentLanguage].SuccessfulUpdateConfigurationShutdown}`);
      })
    }
  }

  openNotification(message, GameKey) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      const disableds = `disabled${GameKey}`
      const disabledbutton = `disabledbutton${GameKey}`
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        [disabledbutton]: false,
        [disableds]: false,
      });
    }, 1500);
  };

  DateopenNotification(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}${Words || ''}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  async updateAll(idGames) {
    let gotError = false
    let typeOfError = ''
    for (let i = 0; i < availablePosition.length; i += 1) {
      if (availablePosition[i] === 'P1OriPosition' || availablePosition[i] === 'P2OriPosition' || availablePosition[i] === 'P3OriPosition') {
        if (this.state.GamesDetail[idGames][AlphabetToNumb[availablePosition[i]]].length !== 1 && this.state.GamesDetail[idGames][AlphabetToNumb[availablePosition[i]]].length !== 0) {
          gotError = true
          typeOfError = 'Only 1 character in position'
        }
      } 
      else if (this.state.GamesDetail[idGames][AlphabetToNumb[availablePosition[i]]].length !== 4 && this.state.GamesDetail[idGames][AlphabetToNumb[availablePosition[i]]].length !== 0) {
        gotError = true
        typeOfError = `Only 4 digits in ${availablePosition[i]}`
      }
    }
    if (gotError) {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(typeOfError)
      });
    } else {
      const updateAllanswer = await updateAllresult(this.state.GamesDetail[idGames].ID,this.state.GamesDetail[idGames], this.props.username, this.props.token )
      if (updateAllanswer.message === 'Update Successful'){
        this.setState({ notificationType: 'success' }, ()=>{
          this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`);
          this.CallAPI();
        })
      } else if ( updateAllanswer.error === 'database error'){
        this.setState({ notificationType: 'error' }, ()=>{
          this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`);
        })
      }
    }
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }
  
  render() {
    return(
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowY: 'auto', padding: 20 }}> 
        <div>
          <Header2 />
        </div>
        <div className="dcc" style={{ display: 'flex', width:  '100%', flexDirection: 'row' }}>
          <div style={{ display: 'flex', width:  '40%', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>From</div>
            <DatePicker
              onChange = {(date) => this.MaxsetSelectedDate(date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              // popperPlacement="top-right"
              selected={this.state.Max_Date}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
            <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>To</div>
            <DatePicker
              onChange = {(date) => this.setSelectedDate(date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              // popperPlacement="top-right"
              selected={this.state.Min_Date}
              minDate={new Date(this.state.Max_Date)}
              className={ "form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <div style={{ display: 'flex', width:  '60%', flexDirection: 'row' }}>
            <Button style={{width: 100, marginLeft: 10}} onClick={() => this.CallAPI()}>寻找日期</Button>
            <Button style={{width: 100, marginLeft: 10}} onClick={() => this.clearDataF()}>算完整理</Button>
          </div>
        </div>
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div style= {{ width: '100%'}}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th><b> No.</b></th>
                <th><b> ID</b></th>
                <th><b> {language[this.props.currentLanguage].Date}</b></th>
                <th  style={{ minWidth: 100 }}><b> {language[this.props.currentLanguage].Games}</b></th>
                <th><b>{language[this.props.currentLanguage].GameResult}</b></th>
                <th style={{ minWidth: 100 }}><b>已开彩</b></th>
              </tr>
            </thead>
            <tbody>
              { this.state.TodayGameAvailable.map((item, Todayindex) =>{
                return    <tr key={Todayindex}>
                  <td>
                    <div style={{ padding: 5}}>
                      {Todayindex + 1}
                    </div>
                  </td>
                  <td>
                    <div style={{ padding: 5}}>
                      {item.ID}
                    </div>
                  </td>
                  <td>
                    <div style={{ padding: 5}}>
                      {Moment(item.Date).format('YYYY-MM-DD')}
                    </div>
                  </td>
                  <td>
                    <div style={{ padding: 5}}>
                      {(this.state.GDisplay === 'MPTSWBK') ? GamePlatformNumb2[item.Game] : GamePlatformNumb1[item.Game]}
                    </div>
                  </td>
                  <td>
                    <Button variant={ (item.ButtonBoolean === undefined) ? 'primary' : (item.ButtonBoolean === true) ? 'danger' : 'primary'}  style={{ width: 150 }}  onClick={() => this.gameChangeDetail(item, item.Game, Todayindex + 1)}>{language[this.props.currentLanguage].GameResult}</Button>
                  </td>
                  <td>
                    {(item[`Calculated${item.Game}`] === 1 || this.state[`CompleteTick${item.Game}`] === true) && <img src={tickicons} alt="tick" style={{ marginLeft: 10, width: 50, height: 50, borderRadius: 25}}/>}
                  </td>
                </tr>
              })}
            </tbody>
          </Table>
        </div>
        <div style={{ width: '80%', display: 'flex', flexDirection: 'column' }}>
          {this.state.Progressbar1 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('1')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar1} label={`${this.state.Progressbar1}%`} /></div>
           </div>
          }
          {this.state.Progressbar2 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('2')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar2} label={`${this.state.Progressbar2}%`} /></div>
           </div>
          }
          {this.state.Progressbar3 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('3')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar3} label={`${this.state.Progressbar3}%`} /></div>
           </div>
          }
          {this.state.Progressbar4 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('4')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar4} label={`${this.state.Progressbar4}%`} /></div>
           </div>
          }
          {this.state.Progressbar5 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('5')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar5} label={`${this.state.Progressbar5}%`} /></div>
           </div>
          }
          {this.state.Progressbar6 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('6')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar6} label={`${this.state.Progressbar6}%`} /></div>
           </div>
          }
          {this.state.Progressbar7 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('7')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar7} label={`${this.state.Progressbar7}%`} /></div>
           </div>
          }
          {this.state.Progressbar8 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('8')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar8} label={`${this.state.Progressbar8}%`} /></div>
           </div>
          }
          {this.state.Progressbar9 > 0 && 
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
             <div style={{ width: 'calc(100% / 10)', fontWeight: 'bold' }}>{ace4dToBtace('9')}</div>
             <div style={{ width: 'calc(100% / 1)'}}><ProgressBar animated now={this.state.Progressbar9} label={`${this.state.Progressbar9}%`} /></div>
           </div>
          }
        </div>
        <div className="dcc" style={{ padding: 20 }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {this.state.GamesDetail.map((Games, Gamesindex) => {
              return <div key={Gamesindex} style={{ flex: "1 1 30%", border: "1px solid black", padding: 10, backgroundColor: ( ( Moment(Games.Date).format('YYYY-MM-DD') === this.state.TodayDate) && Games.autoUpdate) ? '#62f931' : 'grey'}}>
                <Fragment>
                  <div>
                    <div className={(Games.Game === "1") ? "Magnumtittle dcc" : 
                      (Games.Game === "2") ? "Damacaitittle dcc": 
                        (Games.Game === "3") ? "Tototittle dcc" : 
                          (Games.Game === "4") ? "Sgtittle dcc" : 
                            (Games.Game === "5") ? "Cashtittle dcc" :
                              (Games.Game === "6") ? "Sabahtittle dcc":  
                                (Games.Game === "7") ? "Stctittle dcc": 
                                  (Games.Game === "8") ?"GDtittle dcc" : "Ninelottotittle dcc"}
                    style={{ padding: '1%'}}
                    >
                      {Moment(Games.Date).format('YYYY-MM-DD')}
                      {(Games.Game === "1") ? ' Magnum' : 
                        (Games.Game === "2") ? ' Damacai' : 
                          (Games.Game === "3") ? ' Toto' : 
                            (Games.Game === "4") ? ' Singapore' : 
                              (Games.Game === "5") ? ' Sarawak' :
                                (Games.Game === "6") ? ' Sabah' :  
                                  (Games.Game === "7") ? ' Sandakan' :
                                    (Games.Game === "8") ? ' Grand Dragon' : ' 9 Lotto'}
                      <Button disabled={( Moment(Games.Date).format('YYYY-MM-DD') === this.state.TodayDate) ? false : true} variant={ ( Moment(Games.Date).format('YYYY-MM-DD') === this.state.TodayDate) && this.state.autoUpdate ?  'success' : 'primary'}  onClick={() => this.StatusGames(Games.ID, Games.Game)} 
                        style={{ fontSize: 20, marginLeft: '5%', width: "100px", }}>
                        { (  Moment(Games.Date).format('YYYY-MM-DD') === this.state.TodayDate) && this.state.autoUpdate  ? '已开启自动更新' : '开启自动更新' }
                      </Button>
                      {this.state.Authority.CompileReport === 1 && 
                      <>
                        { (Games['Calculated'+Games.Game] === 0) && <Button   variant={'primary'} disabled={this.state[`disabledbutton${Gamesindex}`]} onClick={() => this.CalcuGame(Gamesindex ,Games.Game, Games, Gamesindex )} 
                          style={{ fontSize: 20, width: "100px", marginLeft: '5%' }}>
                          { '计算成绩' }
                        </Button>}
                        { ( Games['Calculated'+Games.Game] === 1 && this.props.Role === "superAdmin") && <Button   variant={'primary'} disabled={this.state[`disabledbutton${Gamesindex}`]} onClick={() => this.CalcuGame(Gamesindex ,Games.Game, Games, Gamesindex )} 
                          style={{ fontSize: 20, width: "100px", marginLeft: '5%' }}>
                          { '从新计算成绩' }
                        </Button>}
                      </>
                      }
                    </div>
                    <div style={{ display: 'flex',  color: 'white' }}>
                      {['1st Prize 首獎', '2nd Prize 二獎', '3rd Prize 三獎'].map((item, normalindex) => {
                        return <div key={normalindex} className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>{item}</div>
                      })}
                    </div>
                    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                      {['P1', 'P2', 'P3'].map((item, index) => {
                        return <div key={index} style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
                          <input disabled={this.state[`disabled${Gamesindex}P${index}`]} maxLength="4" value={Games[item]}   ref={(input) => { this[`${Games.Game + item}`] = input; }}  onChange={(e) => this.setPropsState( Games.ID, item, e.target.value, Gamesindex)} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.onKeyPress(e, Games.Game, item + 'OriPosition', item, Gamesindex, `P${index}`)}/>
                          <input disabled={this.state[`disabled${Gamesindex}PA${index}`]} maxLength="1" value={Games[item + 'OriPosition']}   ref={(input) => { this[Games.Game + item + 'OriPosition'] = input; }}  onChange={(e) => this.setPropsState( Games.Game, item + 'OriPosition', e.target.value, Gamesindex)} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.onKeyPress(e, Games.Game, (`${Games.Game + item + 'OriPosition'}` !== `${Games.Game + 'P3OriPosition'}`) ? 'P' +  (index + 2) : 1, item + 'OriPosition', Gamesindex, `PA${index}`)}/>
                        </div>
                      })}
                    </div>
                    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>Special 特別獎</div>
                    <div style={{ display: 'flex',marginTop:3,  color: 'white', flexWrap: 'wrap', justifyContent: 'center' }}>
                      {((Games.Game === '2' || Games.Game === '4' || Games.Game === '5') ? this.state.Special10Numb : this.state.SpecialNumb).map((item, indexs) =>{
                        return <div key={indexs} style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
                          <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>{characterList[item]}</span>
                          <input disabled={this.state[`disabled${Gamesindex}${indexs}`]} maxLength="4" value={Games[item]}  ref={(input) => { this[`${Games.Game + item}`] = input; }} onChange={(e) => this.setPropsState( Games.ID, item, e.target.value, Gamesindex)}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) =>  this.onKeyPress(e, Games.Game, ( `${Games.Game + item}` === `${Games.Game + 13}`) ? 14 : this.state.SpecialNumb[indexs + 1], item, Gamesindex, indexs )}/>
                        </div>
                      })}
                    </div>
                    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>
                Consolation 安慰獎
                    </div>
                    <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                      {this.state.ConsolationNumb.map((item, indexc) =>{
                        return <div key={indexc} style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
                          <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>{characterList[item]}</span>
                          <input disabled={this.state[`disabled${Gamesindex}C${indexc}`]} maxLength="4" value={Games[item]}  ref={(input) => { this[`${Games.Game + item}`] = input; }}  onChange={(e) => this.setPropsState( Games.ID, item, e.target.value, Gamesindex)}className="resultbottom" style={{width:'calc(100%)'}} type="text" 
                            onKeyPress={(e) =>  this.onKeyPress(e, Games.Game,
                              (( `${Games.Game + item}` === `${Games.Game + 23}`) && (Games.Game === '8') ) ? '5D1' : 
                                (( `${Games.Game + item}` === `${Games.Game + 23}`) && (Games.Game === 'T')) ? '5D1' : 
                                  (( `${Games.Game + item}` === `${Games.Game + 23}`) && (Games.Game === '9')) ? '5D1' : 
                                    ( `${Games.Game + item}` === `${Games.Game + 23}`) ? 'P1' : 
                                      this.state.ConsolationNumb[indexc + 1], item, Gamesindex, `C${indexc}` )}/>
                        </div>
                      })}
                    </div>
                    {(Games.Game === '3') && <div>
                      <div className="Tototittle">SportsToto 5D</div>
                      <div style={{ display: 'flex',  color: 'white' }}>
                        {['1st Prize 首獎', '2nd Prize 二獎', '3rd Prize 三獎'].map((item, index5toto) => {
                          return <div key={index5toto} className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>{item}</div>
                        })}
                      </div>
                      <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                        {['5D1', '5D2', '5D3'].map((item, index5) => {
                          return <div key={index5}  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
                            <input disabled={this.state[`disabled${Gamesindex}5T${index5}`]}  maxLength="5" value={Games[item]}  ref={(input) => { this[`${Games.Game + item}`] = input; }} onChange={(e) => this.setPropsState(  Games.ID, item, e.target.value, Gamesindex )} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.onKeyPress(e, Games.Game, (`${Games.Game + item}` === `${Games.Game + '5D3'}` ) ? '6D1' :'5D' + (index5 + 2), item, Gamesindex, `5T${index5}`)}/>
                          </div>
                        })}
                      </div>
                      <div className="Tototittle">SportsToto 6D</div>
                      <div style={{ display: 'flex',  color: 'white' }}>
                        <div className="border"style={{ width: 'calc(100%)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
                      </div>
                      <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                        <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                          <input maxLength="6" disabled={this.state[`disabled${Gamesindex}6D`]} value={Games['6D1']} ref={(input) => { this[`${Games.Game + '6D1'}`] = input; }} onChange={(e) =>  this.setPropsState(Games.ID, '6D1', e.target.value, Gamesindex)} className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) => this.onKeyPress(e, Games.Game, 'P1','6D1', Gamesindex, '6D' )} />
                        </div>
                      </div>
                    </div>
                    }
                    {/*BT4*/ }
                    {( Games.Game === '8') && <div>
                      <div className="Boomtowntittle"> Grand Dragon 5D</div>
                      <div style={{ display: 'flex',  color: 'white' }}>
                        {['1st Prize 首獎', '2nd Prize 二獎', '3rd Prize 三獎'].map((item, index5Prize) => {
                          return <div key={index5Prize} className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>{item}</div>
                        })}
                      </div>
                      <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                        {['5D1', '5D2', '5D3'].map((item, index5) => {
                          return <div key={index5}  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
                            <input disabled={this.state[`disabled${Gamesindex}B5${index5}`]} maxLength="5" value={Games[item]}  ref={(input) => { this[`${Games.Game + item}`] = input; }}   onChange={(e) => this.setPropsState(  Games.ID, item, e.target.value, Gamesindex )} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.onKeyPress(e, Games.Game, (`${Games.Game + item}` === `${Games.Game + '5D3'}` ) ? '6D1' :'5D' + (index5 + 2), item, Gamesindex, `B5${index5}`)}/>
                          </div>
                        })}
                      </div>
                      <div className="Boomtowntittle"> Grand Dragon 6D</div>
                      <div style={{ display: 'flex',  color: 'white' }}>
                        <div className="border"style={{ width: 'calc(100%)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
                      </div>
                      <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                        <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                          <input disabled={this.state[`disabled${Gamesindex}B6D`]} maxLength="6" value={Games['6D1']} ref={(input) => { this[`${Games.Game + '6D1'}`] = input; }} onChange={(e) =>  this.setPropsState(Games.ID, '6D1', e.target.value, Gamesindex)}  className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) =>  this.onKeyPress(e, Games.Game, '7D1', '6D1', Gamesindex, 'B6D' )}/>
                        </div>
                      </div>
                      <div className="Boomtowntittle"> Grand Dragon 6D + 1</div>
                      <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                        <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                          <input disabled={this.state[`disabled${Gamesindex}B7D`]} maxLength="7" value={Games['7D1']} ref={(input) => { this[`${Games.Game + '7D1'}`] = input; }}  onChange={(e) => this.setPropsState(Games.ID, '7D1', e.target.value, Gamesindex)}className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) => this.onKeyPress(e, Games.Game, 'P1', '7D1', Gamesindex, 'B7D' )}/>
                        </div>
                      </div>
                    </div>}
                    {/*9 lotto*/ }
                    {( Games.Game === '9') && <div>
                      <div className="Ninelottotittle">9 Lotto 5D</div>
                      <div style={{ display: 'flex',  color: 'white' }}>
                        {['1st Prize 首獎', '2nd Prize 二獎', '3rd Prize 三獎'].map((item, index5Prize) => {
                          return <div key={index5Prize} className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>{item}</div>
                        })}
                      </div>
                      <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                        {['5D1', '5D2', '5D3'].map((item, index5) => {
                          return <div key={index5}  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
                            <input disabled={this.state[`disabled${Gamesindex}B5${index5}`]} maxLength="5" value={Games[item]}  ref={(input) => { this[`${Games.Game + item}`] = input; }}   onChange={(e) => this.setPropsState(  Games.ID, item, e.target.value, Gamesindex )} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.onKeyPress(e, Games.Game, (`${Games.Game + item}` === `${Games.Game + '5D3'}` ) ? '6D1' :'5D' + (index5 + 2), item, Gamesindex, `B5${index5}`)}/>
                          </div>
                        })}
                      </div>
                      <div className="Ninelottotittle">9 Lotto 6D</div>
                      <div style={{ display: 'flex',  color: 'white' }}>
                        <div className="border"style={{ width: 'calc(100%)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
                      </div>
                      <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                        <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                          <input disabled={this.state[`disabled${Gamesindex}B6D`]} maxLength="6" value={Games['6D1']} ref={(input) => { this[`${Games.Game + '6D1'}`] = input; }} onChange={(e) =>  this.setPropsState(Games.ID, '6D1', e.target.value, Gamesindex)}  className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) =>  this.onKeyPress(e, Games.Game, '7D1', '6D1', Gamesindex, 'B6D' )}/>
                        </div>
                      </div>
                      <div className="Ninelottotittle">9 Lotto 7D</div>
                      <div style={{ display: 'flex',  color: 'white' }}>
                        <div className="border"style={{ width: 'calc(100%)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
                      </div>
                      <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                        <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                          <input disabled={this.state[`disabled${Gamesindex}B7D`]} maxLength="7" value={Games['7D1']} ref={(input) => { this[`${Games.Game + '7D1'}`] = input; }} onChange={(e) =>  this.setPropsState(Games.ID, '7D1', e.target.value, Gamesindex)}  className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) =>  this.onKeyPress(e, Games.Game, 'P1', '7D1', Gamesindex, 'B7D' )}/>
                        </div>
                      </div>
                    </div>}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, marginBottom: '2%' }}>
                      <Button variant="primary" onClick={() => this.updateAll(Gamesindex)} style={{ fontSize: 20 }}>
                        {'更新全部'}
                      </Button>
                    </div>
                  </div>
                </Fragment> 
              </div> 
            })}
          </div>
        </div>
        {(this.state.PopScrollBackButton)&&<div class={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
          ^
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GameResult);